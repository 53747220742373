class async {
    static sleep(ms) { return new Promise(resolve => { setTimeout(() => { resolve(true); }, ms); }); }
    async forEach(array, callback) { await Promise.all(array.map((v, i) => callback(v, i, array))); } /**
         * ### WARNING!
         * It's not a complex function to calculate where the variable will be defined,
         * it's only polling, it can affect browser performances, so please use it with caution!
         *
         *  @example
         *  import { async } from '@trefle/framework/module/async';
         *  var test;
         *  setTimeout(() => {
         *      test = true;
         *  }, 5000);
         *  console.log(test);
         *  // Expected output: null
         *  await async.until(() => test);
         *  console.log(test);
         *  // Expected output: true
         */
    static until(clbk) { return new Promise(resolve => { const t = setInterval(() => { if (!!clbk()) {
        resolve(clbk());
    } }, 10); }); }
}
export { async as default, async };
