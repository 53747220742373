// @ts-nocheck
class strings {
    static startsWithThese(searches) { let string = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this; var result = { string: string, searched: searches, startingWith: {}, found: false }; searches.forEach(x => { if (!result.startingWith[x]) {
        return result.startingWith[x] = string.startsWith(x);
    } return false; }); if (Object.values(result.startingWith).includes(true)) {
        result.found = true;
    } return result; }
    static searchAll(key) { let searchIn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this; let startFrom = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0; var indices = []; var index = startFrom; while ((index = searchIn.indexOf(key, startFrom)) > -1) {
        indices.push(index);
        startFrom = index + 1;
    } return indices; }
    static generateRandom(includesOrLength, length) { function rand(min, max) { return Math.floor(Math.random() * (max - min + 1) + min); } var chars = ""; if (Array.isArray(includesOrLength)) {
        if (includesOrLength.length === 0) {
            chars += "abcdefghijklmnopqrstuvwxyz";
            chars += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            chars += "0123456789";
            chars += "!@#$%^&*()_-=+;:,.?";
        }
        else {
            if (includesOrLength.includes("lowercase")) {
                chars += "abcdefghijklmnopqrstuvwxyz";
            }
            if (includesOrLength.includes("uppercase")) {
                chars += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            }
            if (includesOrLength.includes("numbers")) {
                chars += "0123456789";
            }
            if (includesOrLength.includes("symbols")) {
                chars += "!@#$%^&*()_-=+;:,.?";
            }
        }
    }
    else if (typeof includesOrLength == 'number') {
        chars += "abcdefghijklmnopqrstuvwxyz";
        chars += "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        chars += "0123456789";
        chars += "!@#$%^&*()_-=+;:,.?";
        length = includesOrLength;
    } var arr = []; var alphaLength = chars.length - 1; for (var i = 0; i < length; i++) {
        var n = rand(0, alphaLength);
        arr.push(chars[n]);
    } return arr.join(""); }
    static includesThese(includes) { let string = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this; var result = { string: string, searched: includes, included: {}, complete: false, found: false }; includes.forEach(x => { if (!result.included[x]) {
        return result.included[x] = string.includes(x);
    } return false; }); if (!Object.values(result.included).includes(false)) {
        result.complete = true;
    } if (Object.values(result.included).includes(true)) {
        result.found = true;
    } return result; } /**
         * @param separator Where to split
         * @param limit The limit of how many times the string should be splitted where the separator is found
         * @param ignoreNext Ignore next separators and make the last result in the array show the rest of the string instead of the rest of the string before the next separator
         */
    static altSplit(separator) { let limit = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0; let ignoreNext = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false; let string = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : this; var results = []; const chars = [...string.matchAll(new RegExp(`${[...separator].map((k, i) => "\\" + k).join('')}`, 'gm'))]; chars.forEach((ch, i, a) => { if (limit !== 0)
        if (i + 1 > limit)
            return; var result; if (!results[0])
        results.push(string.substring(0, ch.index)); var nextChar = chars[i + 1] ? chars[i + 1] : { index: string.length }; if (ignoreNext === true && limit !== 0)
        if (i + 1 >= limit)
            nextChar = { index: string.length }; result = string.substring(ch.index + 1, nextChar.index); results.push(result); }); return results; }
} /** @const @type {Function} */
String.prototype.includesThese = strings.includesThese;
String.prototype.startsWithThese = strings.startsWithThese;
String.prototype.altSplit = strings.altSplit;
export { strings as default, strings };
