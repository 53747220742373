import strings from "../lib/module/strings";
const backupCX = { error: console.error, warn: console.warn };
console.error = function () { for (var _len = arguments.length, content = new Array(_len), _key = 0; _key < _len; _key++) {
    content[_key] = arguments[_key];
} if (!content)
    return; var found = false; content.forEach(v => { if (typeof v === "string" && strings.startsWithThese(["Warning"], v).found) {
    found = true;
} }); if (!found) {
    backupCX.error(...content);
} };
console.warn = function () { for (var _len2 = arguments.length, content = new Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
    content[_key2] = arguments[_key2];
} if (!content)
    return; var found = false; content.forEach(v => { if (typeof v === "string" && (v.toLowerCase().includes("tippy.js") || v.toLowerCase().includes("react router"))) {
    found = true;
} }); if (!found) {
    backupCX.warn(...content);
} };
